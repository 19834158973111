<template>
    <div class="content-wrapper " style="margin-top: 120px;">
        <div class="header-detail pt-lg--5" v-if="event">
            <div class="container-fluid">
                <div class="container">
                    <div class="row pb-5 header-min-height">
                        <div class="col-lg-10">
                            <div class="row">
                                <div class="col-lg-3 col-sm-5 col-md-4 mb-sm-7">
                                    <div class="rounded shadow white-bg">
                                        <img :src=" event.thumbnail" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="col-lg-9 col-sm-7 col-md-8 pt-3 pl-xl-4 pl-lg-5 pl-sm-4">
                                    <span class="badge badge-secondary mb-3">{{event.category_name}}</span>
                                    <h2 class="font-lg fw-600">{{ event.title }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TABBED MENU -->
        <div class="container-fluid">
            <div class="container">
                <div class="row pt-5">
                    <div class="col-lg-9 order-lg-1 order-2 col-lg-push-3 pr-lg-5">
                        <h3 class="fw-500">Deskripsi</h3>
                        <div class="mb-5" v-html="event.content"></div>
                    </div>
                    <div class="col-lg-3 order-lg-2 order-1 pl-lg-4 mb-5 event-info">
                        <div class="mb-5">
                            <h3 class="fw-500">Jadwal Pelaksanaan</h3>
                            <div class="row">
                                <div class="col-sm-12">{{ formatReadableDate(event.schedule) }}</div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <h3 class="fw-500">Lokasi</h3>
                            <div class="row">
                                <div class="col-sm-1"><i class="fas fa-map-marker-alt"></i></div>
                                <div class="col-sm-10">
                                    <p>{{event.location}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
export default {
    data(){
        return {
            idEvent: this.$route.params.idEvent,
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            event: null,
            scr: [],
        }
    },
    created(){
        this.getEvent();
    },
    methods: {
        async getEvent(){
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/events/event/detail?id=${this.idEvent}`)
            .then(async (res) => {
                this.event = res.data.data
                this.isLoad = false
            })
        },
        formatReadableDate(date) {
            return moment(date).format('DD MMMM YYYY, HH:mm'); // Format tanggal menggunakan moment.js
        },
    }
}
</script>
